<template>
	<div class="feed" v-if="isdiv">
		<!-- 导航栏 -->
		<van-nav-bar class="nav_bar_color" title="试乘试驾报告" left-text="返回" left-arrow @click-left="onClickLeft" fixed
			z-index="11" :border="false" placeholder>
		</van-nav-bar>
		<!-- 头部 -->
		<div class="head">
			<!-- 导航栏占位 -->
			<img style="width: 100%;" :src="serverUrl(feedbackinfo.head_image)" mode="widthFix">
			<div class="feed_concat">
				<div class="feed_concat_name">
					尊敬的{{feedbackinfo.name}}：
				</div>
				
				<div class="feed_concat_content">
					感谢您在{{getDateTime2(feedbackinfo.finishtime)}}参与了凯迪拉克{{feedbackinfo.model_name}}的试乘试驾体验，在本次体验中
				</div>
				
				<div class="feed_list">
					<div class="feed_list_title">
						最吸引您的车辆体验是哪些
					</div>
					<div class="feed_list_flex">
						<div style="display: flex;justify-content: center;align-items: center;" class="feed_list_titile">
							<div class="feed_list_titile_name">
								{{attract_feedback[0].content}}
							</div>
						</div>
						
						<img :src="attract_feedback[0].images" mode="">
						<img v-if="attract_feedback.length > 1" :src="attract_feedback[1].images" mode="">
					
						<div style="display: flex;justify-content: center;align-items: center;"  v-if="attract_feedback.length > 1" class="feed_list_titile">
							<div class="feed_list_titile_name">
								{{attract_feedback[1].content}}
							</div>
						</div>
						
						<div  style="display: flex;justify-content: center;align-items: center;"  v-if="attract_feedback.length > 2" class="feed_list_titile">
							<div class="feed_list_titile_name">
								{{attract_feedback[2].content}}
							</div>
						</div>
						
						<img v-if="attract_feedback.length > 2" :src="attract_feedback[2].images" mode="">
					</div>
				</div>
				
				<div class="feed_list">
					<div class="feed_list_title">
						是否有需要改善的车辆体验
					</div>
					<div class="feed_list_flex">
						<div style="display: flex;justify-content: center;align-items: center;" class="feed_list_titile">
							<div class="feed_list_titile_name">
								{{better_feedback[0].content}}
							</div>
						</div>
						
						<img :src="better_feedback[0].images" mode="">
						<img v-if="better_feedback.length > 1" :src="better_feedback[1].images" mode="">
					
						<div style="display: flex;justify-content: center;align-items: center;"  v-if="better_feedback.length > 1" class="feed_list_titile">
							<div class="feed_list_titile_name">
								{{better_feedback[1].content}}
							</div>
						</div>
						
						<div  style="display: flex;justify-content: center;align-items: center;"  v-if="better_feedback.length > 2" class="feed_list_titile">
							<div class="feed_list_titile_name">
								{{better_feedback[2].content}}
							</div>
						</div>
						
						<img v-if="better_feedback.length > 2" :src="better_feedback[2].images" mode="">
					</div>
				</div>
				
				<div class="feed_list">
					<div class="feed_list_title">
						噪音
					</div>
					<div class="feed_list_Regular">
						在本次试乘试驾体验中，怠速下，车外最大噪音值为{{feedbackinfo.idling_noise}}分贝，相当于{{getNoiseValue(feedbackinfo.idling_noise)}}；车内最小噪音值值为：{{feedbackinfo.down_noise}}分贝。
					</div>
					<div class="feed_list_flex">
						<div class="feed_list_titile">
							<div class="feed_list_titile_name">
								车外最大噪音值
							</div>
							<div class="feed_list_number">
								{{feedbackinfo.idling_noise}}<span>分贝</span>
							</div>
							<div class="feed_list_titile_concat">
								相当于{{ getNoiseValue(feedbackinfo.idling_noise) }}
							</div>
						</div>
						
						<img :src="Imagelist[0]" mode="">
						<img :src="Imagelist[1]" mode="">
					
						<div class="feed_list_titile">
							<div class="feed_list_titile_name">
								车内最小噪音值
							</div>
							<div class="feed_list_number">
								{{feedbackinfo.down_noise}}<span>分贝</span>
							</div>
							<div class="feed_list_titile_concat">
								相当于{{ getNoiseValue(feedbackinfo.down_noise) }}
							</div>
						</div>
					</div>
				</div>
				
				<div class="feed_list">
					<div class="feed_list_title">
						空气净化
					</div>
					<div class="feed_list_Regular">
						户外空气指数{{feedbackinfo.clean_air}}，相当于{{getAirScene(feedbackinfo.clean_air)}}；车内空气指数{{feedbackinfo.down_air}},相当于{{getAirScene(feedbackinfo.down_air)}}。
					</div>
					<div class="feed_list_flex">
						<div class="feed_list_titile">
							<div class="feed_list_number font_a">
								{{feedbackinfo.clean_air}}
							</div>
							<div class="feed_list_titile_concat font_b">
								户外空气指数
							</div>
							<div class="feed_list_titile_name font_c">
								30分钟空气净化效果
							</div>
						</div>
						
						<img :src="Imagelist[2]" mode="">
						<img :src="Imagelist[3]" mode="">
					
						<div class="feed_list_titile">
							
							<div class="feed_list_number font_a">
								{{feedbackinfo.down_air}}
							</div>
							<div class="feed_list_titile_concat font_b">
								空气质量指数
							</div>
							<div class="feed_list_titile_name font_c">
								空气净化下降值
							</div>
						</div>
					</div>
				</div>
				
				<div class="feed_appraise">
					<div class="feed_appraise_title">
						您对本次试乘试驾的评价
					</div>
					<div class="feed_appraise_number">
						{{feedbackinfo.score}}<span>分</span>
					</div>
					<div class="feed_appraise_footer">
						您对本次试乘试驾评价为：{{feedbackinfo.score}}分（1-10），您非常愿意向朋友推荐凯迪拉克的产品。
					</div>
				</div>
				
				<!-- 个人信息组件 -->
				<div class="driveinfo_footer">
					<img :src="serverUrl(feedbackinfo.avatar)" mode="aspectFill">
					<span class="driveinfo_footer_name">{{feedbackinfo.bio}}</span>
					<span class="driveinfo_footer_title">您的专属销售顾问{{feedbackinfo.username}}随时为您服务</span>
				</div>
				
				<div class="driveinfo_user">
					<div class="">
						<img src="@/assets/drivroute/icon_dh.png" mode="">
						<span>{{feedbackinfo.user_contact}}</span>
					</div>
					<div class="">
						<img src="@/assets/drivroute/icon_sj.png" mode="">
						<span>{{feedbackinfo.store_contact}}</span>
					</div>
				</div>
				
				<div class="footer">
					未尽事宜后续再与您沟通联系，非常感谢支持
				</div>
				</div>
			</div>
		
	</div>
</template>

<script>
	export default {
		data(){
			return{
				Imagelist:['http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/book1.png','http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/book2.png','http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/book3.png','http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/book4.png'],
				feedbackinfo:{},
				number:'CD869223010600004',
				attract_feedback:[],
				better_feedback:[],
				isdiv:false
			}
		},
		mounted(){
			this.number = this.$route.query.number
			this.getfeedbackinfo()
		},
		methods:{
			getNoiseValue(value){
				 let str = ''
				 switch(true){
					case value < 20:
					str = '实验室';
					break;
					case value >= 20 && value < 30:
					str = '1000米深海';
					break;
					case value >= 30 && value < 35:
					str = '漫天飞雪之中';
					break;
					case value >= 35 && value < 40:
					str = '安静的图书馆';
					break;
					case value >= 40 && value < 45:
					str = '一个人的安静房间';
					break;
					case value >= 45 && value < 60:
					str = '户外公园';
					break;
					case value >= 60 && value < 80:
					str = '室外马路边';
					break;
					case value >= 80 && value < 90:
					str = '喧嚣的十字路口';
					break;
					case value >= 90 && value < 100:
					str = '鸣笛的大货车旁';
					break;
					case value >= 100 && value < 120:
					str = 'F1赛道';
					break;
					case value >= 120 && value <= 150:
					str = '喷气式飞机起飞';
					break;
					case value > 150:
					str = '火箭发射场';
					break;
					default:
				 console.log(value,'噪音环境值错误');
				 }
				 return str;
			},
			// 空气
			getAirScene(value){
				let txt = ''
				switch(true){
					case value <= 20:
						txt = '青藏高原阳光之城-日喀则';
						break;
					case value > 20 && value <= 35:
						txt = '云南香格里拉普达措';
						break;
					case value > 35 && value <= 50:
						txt = '海南三亚海滩';
						break;
					case value > 50 && value <= 100:
						txt = '良好';
						break;
					case value > 100 && value <= 150:
						txt = '一般';
						break;
					case value > 150 && value <= 200:
						txt = '中度污染';
						break;
					case value > 200 && value <= 300:
						txt = '重度污染';
						break;
					case value > 300 && value <= 500:
						txt = '无法呼吸';
						break;
					case value > 500:
						txt = '地狱';
						break;
					default:
						console.log(value, '获取初空气环境错误');
				}
				return txt
			},
			// 时间格式化
			getDateTime2(timestamp) {
				const date = new Date(timestamp * 1000)
				const Y = date.getFullYear() + '年'
				const M = (date.getMonth() + 1 < 10 ? (date.getMonth() + 1) : date.getMonth() + 1) + '月'
				const D = (date.getDate() < 10 ? date.getDate() : date.getDate()) + '日'
				return M + D
			},
			// 获取详情
			async getfeedbackinfo(){
				const {data:res} = await this.$http.post('/api/scsj/h5_get_report_1_7',{number:this.number})
				if(res.code == 1){
					console.log(res.data.res)
					this.feedbackinfo = res.data.res
					this.feedbackinfo.attract_feedback.forEach(item => {
						if(item.attract_feedback == 1){
							this.attract_feedback = item.res
						}
					})
					this.attract_feedback.forEach((item,index) => {
						this.attract_feedback[index].images = this.serverUrl(item.images)
					})
					console.log('1111',this.attract_feedback)
					this.feedbackinfo.better_feedback.forEach(item => {
						if(item.better_feedback == 2){
							this.better_feedback = item.res
						}
					})
					this.better_feedback.forEach((item,index) => {
						this.better_feedback[index].images = this.serverUrl(item.images)
					})
				}
				this.isdiv = true
			},
		}
		
		}
</script>

<style lang="scss" scoped>
.feed_concat{
	padding: 40px;
	.feed_concat_name{
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #FFFFFF;
		line-height: 36px;
	}
	.feed_concat_content{
		padding: 30px 0;
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		color: #E7E7E7;
		line-height: 48px;
	}
	.feed_list{
		margin-top: 50px;
		.feed_list_title{
			padding-bottom: 35px;
			font-size: 36px;
			font-family: Regular;
			font-weight: normal;
			text-align: center;
			color: #FFFFFF;
		}
		.feed_list_Regular{
			font-size: 28px;
			font-family: Regular;
			font-weight: 400;
			color: #C4C4C4;
			line-height: 48px;
			padding-bottom: 35px;
		}
		.feed_list_flex{
			display: flex;
			text-align: center;
			flex-wrap: wrap;
			img{
				width: 335px;
				height: 335px;
				margin-top: 12px;
			}
		}
	}
}

.feed_list_titile{
	margin-top: 14px;
	padding: 50px 24px;
	width: 335px;
	box-sizing: border-box;
	height: 335px;
	background: linear-gradient(226deg, #4A46F6 0%, #0A0951 100%);
	.feed_list_titile_name{
		font-size: 36px;
		font-family: Regular;
		font-weight: 400;
		color: #FFFFFF;
	}
	.feed_list_titile_concat{
		font-size: 32px;
		font-family: Regular;
		font-weight: 400;
		color: #FFFFFF;
		margin-top: 30px;
	}
	.feed_list_number{
		margin-top: 26px;
		text-align: center;
		font-size: 80px;
		font-family: Regular;
		font-weight: 400;
		color: #FFFFFF;
		span{
			font-size: 28px;
			font-family: Regular;
			font-weight: 400;
			color: #FFFFFF;
		}
	}
}

.feed_appraise{
	margin-top: 60px;
	padding: 60px 36px;
	text-align: center;
	width: 670px;
	box-sizing: border-box;
	height: 492px;
	background: url('http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/book_bj.png');
	background-repeat: 100% 100%;
	background-repeat: no-repeat;
	.feed_appraise_title{
		font-size: 36px;
		font-family: Regular;
		font-weight: 400;
		color: #000000;
	}
	.feed_appraise_number{
		padding: 60px;
		font-size: 120px;
		font-family: Medium;
		font-weight: 500;
		color: #C8800D;
		span{
			font-size: 28px;
			font-family: Regular;
			font-weight: 400;
			color: #000000;
		}
	}
	.feed_appraise_footer{
		font-size: 28px;
		font-family: Regular;
		font-weight: 400;
		color: #666666;
		line-height: 48px;
	}
	
}

.driveinfo_user{
	text-align: center;
	div{
		margin-top: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		img{
			width: 40px;
			height: 40px;
		}
		span{
			margin-left: 16px;
			font-size: 28px;
			font-family: Regular;
			font-weight: 400;
			color: #FFFFFF;
		}
	}
}

.driveinfo_footer{
	margin-top: 50px;
		padding: 40px;
		display: flex;
		align-items: center;
		flex-direction:column;
		img{
			width: 120px;
			height: 120px;
			border: 1px solid #FA0037;
			border-radius: 50%;
		}
		.driveinfo_footer_name{
			margin-top: 32px;
			font-size: 40px;
			font-family: HYQiHeiY3-45;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 40px;
		}
		.driveinfo_footer_title{
			margin-top: 32px;
			font-size: 32px;
			color: #BFBFBF;
			line-height: 32px;
		}
	}
	


.footer{
	text-align: center;
	margin-top: 60px;
	font-size: 24px;
	font-family: Regular;
	font-weight: 400;
	color: #818181;
}

.font_a{
	margin-top: 0 !important;
}
.font_b{
	margin-top: 0 !important;
	font-size: 24px !important;
}
.font_c{
	margin-top: 30px !important;
	font-size: 28px !important;
}
</style>